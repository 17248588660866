import React from 'react';
import PropTypes from 'prop-types';
import { DropzoneAreaBase } from 'react-mui-dropzone';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';

import {
  theme, TitleText, DropZoneContainer,
  MiddleText, BrowseFileButton
} from './fileDropzone.styles';
import { useStateValue } from '../../../../providers/stateProvider';
import { BlueUploadCloud } from '../../../../assets/svgs';

const DropzoneContent = ({ titleInfo }) => (
  <DropZoneContainer>
    <BlueUploadCloud style={{ fontSize: '3rem', fill: 'none' }} />
    <TitleText>
      {titleInfo}
      <MiddleText>
        JPG size not more than 913 Px by 350 Px
        <br />
        (File Size 1mb)
      </MiddleText>
    </TitleText>
    <BrowseFileButton>
      Select file
    </BrowseFileButton>
  </DropZoneContainer>
);
DropzoneContent.propTypes = {
  titleInfo: PropTypes.string
};
DropzoneContent.defaultProps = {
  titleInfo: 'Select a file or drag and drop here '
};

const DropZone = ({
  handleFile,
  titleInfo,
  handleCancle,
  name
}) => {
  const [{
    user: { allowedPermissionsMap: { userPermissions } }
  }] = Object.values(useStateValue());

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <DropzoneAreaBase
          titleInfo={titleInfo}
          // onChange={handleFile}
          acceptedFiles={['image/*,application/']}
          dropzoneText={<DropzoneContent titleInfo={titleInfo} />}
          fileslimit={1}
          onAdd={(e) => handleFile(e, name)}
          onDelete={handleCancle}
          clearOnUnmount
          style={{
            pointerEvents: !userPermissions?.includes('manage_affiliates_upload_files') ? 'none' : ''
          }}
        />
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

DropZone.propTypes = {
  handleFile: PropTypes.func.isRequired,
  handleCancle: PropTypes.func.isRequired,
  titleInfo: PropTypes.string,
  name: PropTypes.string.isRequired
};
DropZone.defaultProps = {
  titleInfo: 'Select a file or drag and drop here '
};

export default DropZone;
