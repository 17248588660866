import { styled } from '@mui/material/styles';
import { Grid, Typography } from '@mui/material';
import { AdminLogo, MPAuthImage, WarehouseLogo } from '../../assets/svgs';

export const AuthGridContainer = styled(Grid)`
  position: relative;
  width: 100vw;
  height: 100vh;

  @media (max-width: 991px) {
    overflow: auto;
  }
`;

export const BackgroundImage = styled('img')`
  width: 150vw;
  height: 100vh;

  @media (min-width: 992px) {
    width: 65vw;
  }
`;

export const Img = styled('img')`
  width: 68%;
  margin: 0 auto;

  @media (min-width: 768px) {
    width: 75%;
  }

  @media (min-width: 992px) {
    width: 75%;
    margin: 0;
  }
  @media (min-width: 1250px) {
    width: 80%;
  }
`;

export const Logo = styled('img')`
  width: 68%;
  margin: 0 auto;

  @media (min-width: 768px) {
    width: 75%;
  }

  @media (min-width: 992px) {
    width: 50%;
    margin: 0;
  }
`;

export const InnerGridContainer = styled(Grid)`


`;

export const LogoGridContainer = styled(Grid)`
  background-image: ${({ authBannerImg }) => `url(${authBannerImg})`};
  flex-direction: column;
  padding: 1rem 3rem;
  justify-content: end;
  padding-bottom: 8rem;
`;

export const InputsPaper = styled(Grid)`
  background-color: #FFFFFF;
  flex-direction: column;
  padding: 6rem 4rem;
  justify-content: flex-start;
  align-items: center;

  @media (min-width: 992px) {
    padding: 0rem 10rem;
    justify-content: center;
  }

`;

export const InputsPaperContainer = styled(Grid)`
  width: 100%;
`;

export const InputsGridContainer = styled(Grid)``;

export const FormWrapper = styled(Grid)`
  padding-top: 10px;
  width: 100%;

  @media (max-width: 991px) {
    width: 100%;
  }
`;

export const TypoWrapper = styled(Grid)`
  color: #fff;
`;

export const LogoTypo = styled(Typography)`
  font-size: 50px;
  font-weight: 600;
  line-height: 60px;

  @media (min-width: 768px) {
    font-size: 32px;
  }

  @media (min-width: 992px) {
    font-size: 52px;
  }
`;
export const TypoDesc = styled(Typography)`
  font-size: 24px;
  font-weight: 500;
  margin-top:  2rem;
  line-height: 23px;
  color: #f5f5f5;

  @media (min-width: 992px) {
    font-size: 15px;
  }
`;

export const InputsHeaderTypo = styled(Typography)`
  color: #24292E;
  font-weight: 700;
  margin-top: 0.3rem;
  margin-bottom: 10px;
  font-size: 3rem;
  white-space: nowrap;

  @media (min-width: 992px) {
  font-size: 30px;
  }
`;

export const SubTypo = styled(Typography)`
  color: #606060;
  font-weight: 400;
  font-size: 16px;
  display: flex;

  @media (max-width: 991px) {
    font-size: 2.2rem;
    padding: 1rem 0 2.5rem;
  }

`;

export const InputsFooter = styled(Grid)`
  text-align: right;
  background-image: linear-gradient(to right, #424242, #0D0D0D);
  clip-path: polygon(0 100%,100% 0,100% 100%,0 100%);
`;

export const OgaImage = styled('img')`
  width: 16rem;
  padding: 2rem 1.5rem .5rem 0;

  @media (min-width: 992px) {
    width: 10vw;
    padding: 1.6vw 1vw 0 0;
  }
`;

export const OgaAdminLogo = styled(AdminLogo)`
  width: 15rem;
  height: 5rem;

  @media (max-width: 991px) {
    width: 28rem;
    height: 13rem;
  }
`;

export const WarehouseLg = styled(WarehouseLogo)`
  width: 16rem;
  height: 5rem;
  margin-left: 1rem;

  @media (max-width: 991px) {
    width: 28rem;
    height: 13rem;
  }
`;

export const MpAuthImage = styled(MPAuthImage)`
&.MuiSvgIcon-root {
  width: 100%;
  height: 65%;
}
`;

export const RatingStyled = styled('div')`
  margin-top: 1.5rem;
  display: flex;
  gap: 0.8rem;
  align-items: center;
`;
