import React from 'react';
import PropTypes from 'prop-types';
import {
  DialogContentText,
  Slide,
  Grid,
  Box
} from '@mui/material';
import { Close } from '@mui/icons-material';
import MapCsvDialog from './mapCsvDialog';
import { useStateValue } from '../../providers/stateProvider';
import {
  DialogContainer,
  CardTitle,
  DialogContentStyled,
  TitleDesc
} from './createBndP.style';

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

export default function CreateProduct({ openProdDialog, toggleHandler }) {
  const [{
    user: { session: { businessType } }
  }] = Object.values(useStateValue());
  const isWholesaler = businessType === 'WHOLESALER';

  const fields = [
    ['Product ID', {
      required: false, unique: false, value: null, empty: true, touched: false, hover: false, show: true,
      rules: 'int,nullable'
    }],
    ['Product Brand Name', {
      required: true, unique: false, value: null, empty: true, touched: false, hover: false, show: true,
      rules: 'str,nullable'
    }],
    ['Molecule', {
      required: true, unique: false, value: null, empty: true, touched: false, hover: false, show: true,
      rules: 'str,nullable'
    }],
    ['Manufacturer', {
      required: true, unique: false, value: null, empty: true, touched: false, hover: false, show: true,
      rules: 'str,nullable'
    }],
    ['Description', {
      required: false, unique: false, value: null, empty: true, touched: false, hover: false, show: true,
      rules: 'str,nullable'
    }],
    ['Pack Size', {
      required: false, unique: false, value: null, empty: true, touched: false, hover: false, show: true,
      rules: 'str,nullable'
    }],
    ['Therapeutic Type', {
      required: true, unique: false, value: null, empty: true, touched: false, hover: false, show: true,
      rules: 'str,nullable'
    }],
    ['Selling Price', {
      required: false, unique: false, value: null, empty: true, touched: false, hover: false, show: true,
      rules: 'str,nullable,money'
    }],

    ['Status', {
      required: true, unique: false, value: null, empty: true, touched: false, hover: false, show: true,
      rules: 'str,nullable'
    }],

    ['Product Variant', {
      required: isWholesaler, unique: false, value: null, empty: true, touched: false, hover: false, show: true,
      rules: 'str,nullable'
    }],
    ['Product Class', {
      required: false, unique: false, value: null, empty: true, touched: false, hover: false, show: true,
      rules: 'str,nullable'
    }],
    ['Create If Not Exist(Yes/No)', {
      required: false, unique: false, value: null, empty: true, touched: false, hover: false, show: true, adminOnly: true
    }],
    ['Image', {
      required: false, unique: false, value: null, empty: true, touched: false, hover: false, show: true,
      rules: 'str,nullable'
    }],
  ];
  const experimentalFields = [
    ['Service Provider', {
      required: false, unique: false, value: null, empty: true, touched: false, hover: false, show: true, adminOnly: true
    }],
    // ['Original Name', {
    //   required: false, unique: false, value: null, empty: true, touched: false, hover: false, show: true, adminOnly: true
    // }],
  ];

  return (
    <DialogContainer
      data-testid="product-dialog"
      open={openProdDialog}
      TransitionComponent={Transition}
      keepMounted
      onClose={toggleHandler}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogContentStyled>
        <DialogContentText>
          <Grid style={{ marginTop: '1rem' }}>
            <Box display="flex" justifyContent="space-between">
              <CardTitle>Import CSV</CardTitle>
              <Close
                fontSize="small"
                onClick={toggleHandler}
                style={{ cursor: 'pointer' }}
              />
            </Box>
            <TitleDesc>Upload CSV File To Add Bulk Product</TitleDesc>
            <MapCsvDialog
              experimentalFields={experimentalFields}
              fields={fields.filter((item) => item[1].show)}
              model="product"
              endpoint="products"
              backRoute="products"
              isWholesaler={isWholesaler}
            />
          </Grid>
        </DialogContentText>
      </DialogContentStyled>
    </DialogContainer>
  );
}

CreateProduct.propTypes = {
  openProdDialog: PropTypes.bool,
  toggleHandler: PropTypes.func.isRequired
};

CreateProduct.defaultProps = {
  openProdDialog: false
};
