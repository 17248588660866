import React from 'react';
import PropTypes from 'prop-types';
import { Grid, useMediaQuery } from '@mui/material';
import { RenderPopper } from '../../../utils/utils';
import {
  ProductItem, SearchPaper, ProdName, CircularProgressLoader, LoaderDiv,
  EmptyItem, EmptyText
} from './productSearchPopper.styles';

const ProductSearchPopper = ({
  searchFieldEl, handleClose, searchedProducts,
  handleProdSelection, loading, width
}) => {
  const isSmall = useMediaQuery('(max-width: 991px)');
  const open = Boolean(searchFieldEl);

  const renderSearchProducts = () => (
    searchedProducts.length < 1 ? (
      <EmptyItem>
        <EmptyText>No results found</EmptyText>
      </EmptyItem>
    )
      : (
        searchedProducts?.map(({ brandName, name }) => (
          <ProductItem key={brandName} container onClick={() => handleProdSelection(brandName || name)}>
            <Grid item xs={12}>
              <ProdName variant="caption">{brandName || name}</ProdName>
            </Grid>
          </ProductItem>
        ))
      )
  );

  return (
    <RenderPopper
      open={open}
      anchorEl={searchFieldEl}
      onClickAway={handleClose}
      popperPlacement="bottom-start"
      marginLeft={isSmall ? '-8.5rem !important' : '-3.5rem !important'}
      marginTop={isSmall ? '4rem !important' : '1rem !important'}
      modifiers={{
        offset: {
          enabled: true,
          offset: '-3vw, 1vh',
        }
      }}
    >
      <SearchPaper elevation={0} width={width}>
        {loading ? (
          <LoaderDiv>
            <CircularProgressLoader
              disableShrink
              size={isSmall ? '4rem' : '2rem'}
              thickness={4}
            />
          </LoaderDiv>
        )
          : (
            renderSearchProducts()
          )}
      </SearchPaper>
    </RenderPopper>
  );
};

ProductSearchPopper.propTypes = {
  searchFieldEl: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired,
  searchedProducts: PropTypes.instanceOf(Array).isRequired,
  handleProdSelection: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  width: PropTypes.number.isRequired
};

export default ProductSearchPopper;
