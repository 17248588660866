import {
  Grid, Typography, IconButton, Popper
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { NavProfileBlue } from '../../assets/svgs';

export const GridContainer = styled(Grid)`
    padding: 15px 25px;
    width: 25rem;
    align-items: center;
    cursor: pointer;

    @media(min-width: 992px) {
      padding: 0px 5px;
      width: 13rem;
    }
`;

export const CPopper = styled(Popper)`
    z-index: 15000000;
    width: 280px;
    background: #ffffff;

  & .MuiPopper-root {
    width: 270px;
  }

`;
export const LogoutTypo = styled(Typography)`
  font-size: 2rem;
  color: #606060;
  font-weight: 400;
  margin-left: 1rem;

  @media(min-width: 992px) {
    font-size: 14px;
  }
`;

export const RoleTypo = styled(Typography)`

  @media(max-width: 991px) {
    font-size: 1.5rem;
  }
`;

export const LogoutIconButtonWrapper = styled(IconButton)`
  border-radius: 0;
  width: 100%;
  padding: 10px 23px;
  // border-top: 1px solid rgba(240,240,240);

  &:hover {
    background: #F3F9FF;
  }
`;

export const IconButtonWrapper = styled(IconButton)`
  border-radius: 0;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  padding: 15px 15px;

  &:hover {
    background: #F3F9FF;
  }
`;

export const LogoutImage = styled('img')`
  width: 40px;
  height: 40px;

  @media(min-width: 992px) {
    width: 20px;
    height: 20px;
  }
`;

export const MenuTypo = styled(Typography)`
  margin-left: 25px;
  font-size: 2rem;
  white-space: nowrap;
  text-transform: capitalize;

  @media(min-width: 992px) {
    margin-left: 15px;
    font-size: 14px;
  }
`;

export const NavIcon = styled(NavProfileBlue)`
  @media(max-width: 991px) {
    font-size: 2.5rem;
  }
`;
