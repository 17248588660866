import { styled } from '@mui/material/styles';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { InputBase, Typography } from '@mui/material';

export const VisIcon = styled(Visibility)`
  font-size: 3rem;

  @media (min-width: 992px) {
    font-size: 1.5rem;
  }
`;

export const VisOffIcon = styled(VisibilityOff)`
  font-size: 3rem;

  @media (min-width: 992px) {
    font-size: 1.5rem;
  }
`;

export const Label = styled(Typography)`
  font-size: .875rem;
  font-weight: 500;
  color: ${({ error }) => (error ? '#ff0000' : '#303030')};
  margin-bottom: .5rem;
  display: flex;
  align-items: center;
  opacity: 0.5;
  gap: 4px;

  @media(max-width: 991px) {
    font-size: 2rem;
    opacity: 1;
  }
`;

export const CInputBase = styled(InputBase)`
  border: ${({ error }) => (error ? '0.5px solid #ff0000' : '0.5px solid #d8d8d8')};
  background: ${({ disabled }) => (disabled ? '#F3F9FF' : '')};
  border-radius: 8px;
  font-size: 14px;
  font-weight: 400;
  line-height: 3.2;
  @media(max-width: 991px) {
    font-size: 2rem;
    border-radius: 1rem;
    border: 1px solid #78AADA;
    font-weight: 500;
     & .MuiInputBase-input {
      height: 3.5rem
    }
  }


  & .MuiInputBase-input {
    padding: ${({ cSize }) => (cSize === 'lg' ? '16px' : '12px')};
    padding-right: 5px;
  }
  & .MuiInputAdornment-positionEnd {
    margin-right: 8px;
    margin-left: 8px;
  }

  & .MuiSvgIcon-root {
    color: #606060;
  }

  &:active, &:focus, &:focus-within {
    border: ${({ error }) => (error ? '1.3px solid #BA1A1A' : '1.3px solid #78AADA')};

    & .MuiSvgIcon-root {
      color: #78AADA;
    }
  }

  @media(max-width: 991px) {
    & .MuiInputBase-input {
      height: 3rem;
      font-size: 1.5rem;
    }
  }
`;

export const HelperText = styled(Typography)`
  font-size: 10px;
  color: #ff0000;
  opacity: 0.8;
`;
