import React, { useState } from 'react';
import { Grid, InputAdornment } from '@mui/material';
import PropTypes from 'prop-types';
import toast from 'react-hot-toast';
import { CaretDownIcon, CaretUpIcon, Dots } from '../../assets/svgs';
import {
  MainTableRow, ProductDetail, ImageWrapper, ProductDetailImage, ProductDetailNameMainContainer, ProductDetailBrandNameText,
  ProductDetailPackSizeText, BodyCell, QuantityText, CustomInputBase, ProductDetailMainContainer, ActionButton, FieldCheckbox
} from './returnProductRow.styles';
import ReturnSelectField from '../orders/dispatch/product-item/returnSelectField';
import SelectBatchDialog from './selectBatchDialog';
import { CheckboxImage } from '../orders/dispatch/returnSelectField.styles';
import CheckboxIcon from '../../assets/images/supplier/Checkbox-selected.png';
import UnCheckboxIcon from '../../assets/images/supplier/Checkbox-resting.png';
import ActionPopper from './actionPopper';
import AllBatchDialog from './BatchDialog';
import SingleBatchDialog from './singleBatchDialog';
import { JSONParse } from '../../utils/json';
import { useStateValue } from '../../providers/stateProvider';

const conditionSelectBoxField = {
  name: 'Condition', label: 'Select',
  options: ['Good', 'Bad']
};

const icon = <CheckboxImage src={UnCheckboxIcon} alt="unchecked" />;
const checkedIcon = <CheckboxImage src={CheckboxIcon} alt="checked" />;

const ReturnProductRow = ({
  order, handleQtyConfirmedIncrement, handleQtyConfirmedDecrement, handleQtySentIncrement, handleConfirmedQtyInputValue,
  handleQtySentDecrement, handleConditionChange, handleBatchSelection, businessType, businessOrderStatus, quantityReceive,
  handleQuantitySentInputValue, deleteBatchHandler, refetchData
}) => {
  const {
    id, name, searchablePackSize, quantityInStock, quantity, quantityConfirmed, quantityReceived, condition, received, image, orderMeta,
    batches: orderBatch
  } = order;
  const orderSorted = ['ORDER_QUEUED_FOR_DISPATCH', 'ORDER_DISPATCHED', 'CLOSED'];
  const { batches } = JSONParse(orderMeta) || [];

  const [{
    user: { session: { businessType: busType } }
  }] = Object.values(useStateValue());

  const updatedQuantity = quantityReceive.find(({ productId }) => productId === id);

  const [open, setOpen] = useState(false);
  const [action, setAction] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [allBatchModal, setAllBatchModal] = useState(false);
  const [singleBatchDetails, setSingleBatchDetails] = useState(null);

  const toggleBatchDialog = () => {
    if (!order.batches.length) return toast.error('Product has no batches');
    setOpen(!open);
  };

  const handleQtySIncrement = (prodId) => {
    if (busType === 'MANUFACTURER' && orderBatch?.length > 1) return setOpen(true);
    handleQtySentIncrement(prodId);
  };

  const handleQtySDecrement = (prodId) => {
    if (busType === 'MANUFACTURER' && orderBatch?.length > 1) return setOpen(true);
    handleQtySentDecrement(prodId);
  };

  const handleOptions = (event) => {
    setAction(action ? null : event.currentTarget);
  };
  const handleCloseModal = () => {
    setAction(null);
  };
  const handleOpenCreateBatch = (edit = false) => {
    if (!edit) setSingleBatchDetails(null);
    setOpenModal(!openModal);
  };

  const handleAction = (event, type) => {
    if (type === 'add batch') {
      handleOpenCreateBatch();
    } else {
      setAllBatchModal(true);
    }
    handleOptions(event);
  };

  const allBatchModalHandler = () => setAllBatchModal(!allBatchModal);

  return (
    <MainTableRow>
      <ProductDetail>
        <ImageWrapper>
          <ProductDetailImage src={image} alt="" />
        </ImageWrapper>
        <ProductDetailNameMainContainer>
          <ProductDetailBrandNameText>
            {name}
          </ProductDetailBrandNameText>
          <ProductDetailPackSizeText>
            Pack Size:
            {' '}
            {searchablePackSize}
          </ProductDetailPackSizeText>
        </ProductDetailNameMainContainer>
      </ProductDetail>
      <BodyCell>
        <QuantityText variant="h6">{quantityInStock}</QuantityText>
      </BodyCell>
      <BodyCell>
        <QuantityText variant="h6">{quantity}</QuantityText>
      </BodyCell>
      <BodyCell>
        <CustomInputBase
          type="number"
          placeholder="0"
          disabled={orderSorted.includes(businessOrderStatus)}
          min={1}
          value={quantityConfirmed}
          onChange={(event) => handleConfirmedQtyInputValue(id, event.target.value)}
          size="medium"
          onWheel={(e) => {
            e.target.blur();
          }}
          endAdornment={(
            <InputAdornment position="end">
              <Grid item container direction="column">
                <CaretUpIcon
                  disabled={orderSorted.includes(businessOrderStatus)}
                  style={{
                    width: '1rem',
                    height: '1rem',
                    cursor: 'pointer',
                    pointerEvents: orderSorted.includes(businessOrderStatus) ? 'none' : ''
                  }}
                  onClick={() => handleQtyConfirmedIncrement(id)}
                />
                <CaretDownIcon
                  style={{
                    width: '1rem',
                    height: '1rem',
                    cursor: 'pointer',
                    pointerEvents: orderSorted.includes(businessOrderStatus) ? 'none' : ''
                  }}
                  onClick={() => handleQtyConfirmedDecrement(id)}
                />
              </Grid>
            </InputAdornment>
        )}
        />
      </BodyCell>
      <BodyCell>
        <CustomInputBase
          type="number"
          placeholder="0"
          min={1}
          size="medium"
          value={quantityReceived}
          disabled={orderSorted.includes(businessOrderStatus) || updatedQuantity?.batches?.length}
          onChange={(e) => handleQuantitySentInputValue(id, e.target.value)}
          onWheel={(e) => {
            e.target.blur();
          }}
          endAdornment={(
            <InputAdornment position="end">
              <Grid item container direction="column">
                <CaretUpIcon
                  onClick={() => handleQtySIncrement(id)}
                  style={{
                    width: '1rem',
                    height: '1rem',
                    cursor: 'pointer',
                    pointerEvents: (orderSorted.includes(businessOrderStatus) || updatedQuantity?.batches?.length) ? 'none' : ''
                  }}
                />
                <CaretDownIcon
                  onClick={() => handleQtySDecrement(id)}
                  style={{
                    width: '1rem',
                    height: '1rem',
                    cursor: 'pointer',
                    pointerEvents: (orderSorted.includes(businessOrderStatus) || updatedQuantity?.batches?.length) ? 'none' : ''
                  }}
                />
              </Grid>
            </InputAdornment>
        )}
        />
      </BodyCell>
      <BodyCell style={{ textAlign: 'center' }}>
        <ProductDetailMainContainer>
          <ReturnSelectField
            field={conditionSelectBoxField}
            value={condition}
            label=""
            handleCreditDaysOpen={() => ({})}
            fullWidth
            handleChange={(e) => handleConditionChange(id, e.target.value, 'condition')}
            showLabel={false}
          />
        </ProductDetailMainContainer>
      </BodyCell>
      <BodyCell>
        <FieldCheckbox
          icon={icon}
          checkedIcon={checkedIcon}
          checked={quantityReceived === 0 && !received}
          onChange={(e) => handleConditionChange(id, !e.target.checked, 'received')}
        />
      </BodyCell>
      { businessType === 'MANUFACTURER' ? (
        <BodyCell>
          <ActionButton
            disabled={orderSorted.includes(businessOrderStatus)}
            onClick={toggleBatchDialog}
          >
            Select Batch
          </ActionButton>
        </BodyCell>
      ) : (
        <BodyCell data-testid="pure-mp-uat-action-popper-td" className="pure-mp-uat-action-popper-td">
          <Dots fontSize="large" onClick={handleOptions} style={{ cursor: 'pointer' }} />
          <ActionPopper
            action={action}
            handleAction={handleAction}
            handleClose={handleCloseModal}
          />
        </BodyCell>
      )}
      <SelectBatchDialog
        open={open}
        toggleBatchDialog={toggleBatchDialog}
        order={order}
        handleBatchSelection={handleBatchSelection}
      />
      <AllBatchDialog
        modalStatus={allBatchModal}
        modalHandler={allBatchModalHandler}
        product={order}
        singleBatchItem={batches}
        deleteBatchHandler={deleteBatchHandler}
        handleOpenCreateBatch={handleOpenCreateBatch}
        setSingleBatchDetails={setSingleBatchDetails}
        refetch={refetchData}
        productId={id}
      />

      <SingleBatchDialog
        open={openModal}
        onClose={() => setOpenModal(false)}
        brandName={name}
        productId={id}
        refetch={refetchData}
        singleBatchItem={batches}
        singleBatchDetails={singleBatchDetails}
        quantity={quantity}
      />
    </MainTableRow>
  );
};

ReturnProductRow.propTypes = {
  order: PropTypes.shape(Object),
  handleQtyConfirmedIncrement: PropTypes.func.isRequired,
  handleQtyConfirmedDecrement: PropTypes.func.isRequired,
  handleQtySentIncrement: PropTypes.func.isRequired,
  handleQuantitySentInputValue: PropTypes.func.isRequired,
  handleQtySentDecrement: PropTypes.func.isRequired,
  handleConditionChange: PropTypes.func.isRequired,
  handleConfirmedQtyInputValue: PropTypes.func.isRequired,
  handleBatchSelection: PropTypes.func.isRequired,
  businessType: PropTypes.string.isRequired,
  businessOrderStatus: PropTypes.string.isRequired,
  quantityReceive: PropTypes.instanceOf(Array).isRequired,
  deleteBatchHandler: PropTypes.func.isRequired,
  refetchData: PropTypes.func.isRequired,
};

ReturnProductRow.defaultProps = {
  order: {},
};

export default ReturnProductRow;
