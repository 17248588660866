import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  IconButton, InputAdornment
} from '@mui/material';
import {
  Label, VisIcon, VisOffIcon, HelperText
} from './passwordTextField.styles';
import CustomTextField from './customTextField';

const PasswordTextField = ({
  password, handleChange, passwordError, loading, label, helperText, name, placeholder
}) => {
  const [showPassword, setShowPassword] = useState(false);

  const errorText = helperText || 'Should be atleast 8 characters. UPPERCASE, lowercase, letters & numbers';

  return (
    <Grid item container direction="column">
      <Grid item container justifyContent="space-between">
        <Label error={passwordError}>
          {label || 'Password'}
          {' '}
          <span style={{ color: 'red' }}>*</span>
        </Label>
      </Grid>
      <CustomTextField
        name={name || 'password'}
        placeholder={placeholder}
        type={showPassword ? 'text' : 'password'}
        required
        fullWidth
        value={password}
        onChange={handleChange}
        error={passwordError ?? false}
        data-testid="auth-password"
        disabled={loading}
        className="signin-password"
        id="signin-password"
        InputProps={{
          classes: { root: 'custom-input-root' },
          endAdornment: (
            <div>
              <InputAdornment position="end">
                <IconButton
                  aria-label="Toggle password visibility"
                  onClick={() => setShowPassword(!showPassword)}
                  style={{ color: '#78AADA' }}
                >
                  {showPassword ? <VisIcon /> : <VisOffIcon />}
                </IconButton>
              </InputAdornment>
            </div>
          )
        }}
        helperText={passwordError ? <HelperText>{errorText}</HelperText> : ' '}
      />
    </Grid>
  );
};

PasswordTextField.propTypes = {
  password: PropTypes.string,
  passwordError: PropTypes.bool,
  loading: PropTypes.bool,
  handleChange: PropTypes.func.isRequired,
  label: PropTypes.string,
  helperText: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
};

PasswordTextField.defaultProps = {
  password: '',
  passwordError: false,
  loading: false,
  label: '',
  helperText: '',
  name: '',
  placeholder: ''
};

export default PasswordTextField;
