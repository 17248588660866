import React from 'react';
import { useQuery } from '@apollo/client';
import { useParams, useNavigate } from 'react-router-dom';
import { ArrowBackIos } from '@mui/icons-material';
import Loader from '../../shared/loader';
import MainContent from '../../customComponents/mainContent';
import ProductInfo from './productInfo';
import {
  OrderGridContainer, Back, TitleTextGridContainer, PrimaryTitle, ButtonContainer,
} from './productDetailsContainer.style';
import { useStateValue } from '../../../providers/stateProvider';
import CustomButton from '../../customComponents/customButton';
import IndividualProductDialog from '../../shared/uploadProduct/individual/individualProductDialog';
import ProductActionTypes from '../../../providers/reducers/product/productTypes';

import { PRODUCT_DETAILS_QUERY } from '../../../queries/products';
import ProductBatchList from './productBatchList';

const ProductDetailsContainer = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const {
    loading, error, data, refetch
  } = useQuery(PRODUCT_DETAILS_QUERY, {
    fetchPolicy: 'cache-and-network',
    variables: {
      id: Number(id),
    },
  });

  const [{
    product: { addProductDialogOpen },
    user: { session: { businessType } }
  }, dispatch] = Object.values(useStateValue());
  const isWholesaler = businessType === 'WHOLESALER';

  if (loading) return <Loader />;
  if (error) return error;
  const {
    product = {}
  } = data || {};

  const { brandName } = product;

  const toggleIndividualDialog = () => {
    dispatch({
      type: ProductActionTypes.UPDATE_PRODUCT,
      payload: { productRow: product, refetch }
    });
    dispatch({ type: ProductActionTypes.TOGGLE_DIALOG_OPEN });
  };

  const closeModal = () => {
    dispatch({ type: ProductActionTypes.TOGGLE_DIALOG_OPEN });
  };

  return (
    <MainContent>
      <OrderGridContainer container>
        <Back onClick={() => navigate('/inventory/products')}>
          <ArrowBackIos fontSize="small" />
          back
        </Back>
        <TitleTextGridContainer>
          <PrimaryTitle>{brandName}</PrimaryTitle>
          <ButtonContainer>
            <CustomButton
              type="tertiary"
              header
              style={{ width: '10rem', height: '2.6rem' }}
              onClick={() => navigate(`/product-logs/${id}`, {
                state: { brandName }
              })}
              className="erp-uat-product-detail-edit-product"
            >
              View Order Log
            </CustomButton>
            <CustomButton
              header
              style={{ width: '10rem', height: '2.6rem' }}
              onClick={() => toggleIndividualDialog()}
              className="erp-uat-product-detail-edit-product"
            >
              Edit Product
            </CustomButton>
          </ButtonContainer>
        </TitleTextGridContainer>
        <ProductInfo info={product} refetch={refetch} />
        {!isWholesaler && <ProductBatchList info={product} refetch={refetch} /> }
        <IndividualProductDialog
          dialogOpen={addProductDialogOpen}
          closeDialog={() => closeModal()}
          businessState={product}
          details
        />
      </OrderGridContainer>
    </MainContent>
  );
};

export default ProductDetailsContainer;
