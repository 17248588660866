import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  useMediaQuery, Box, InputAdornment, Grid
} from '@mui/material';
import { useMutation } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import currencyFormatter from '../../shared/currencyFormatter';
import { UPDATE_PRODUCTS_STATUS_MUTATION } from '../../../mutations/products';
import { UPDATE_ORDER_MUTATION } from '../../../mutations/orders';
import CartActionTypes from '../../../providers/reducers/cart/cartTypes';
import { DisabledCaretDownIcon, DisabledCaretUpIcon, Dots } from '../../../assets/svgs';
import {
  CustomCheckbox, StatusColor, StatusText, TableColumnRows
} from './returnRow.styles';
import { TableColumnRowText } from '../../shared/tableGlobalStyle.styles';
import { Product } from '../../../providers/reducers/product/classes/Product';
import ActionPopper from './actionPopper';
import { useStateValue } from '../../../providers/stateProvider';
import SingleBatchDialog from '../../products/productDetails/singleBatchDialog';
import { CustomInputNumberBase } from '../purchaseOrder/returnRow.styles';

const ReturnRow = ({
  row, selected, handleSelect, refetchData, counterHandler
}) => {
  const [action, setAction] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const isLarge = useMediaQuery('(min-width:992px)');
  const [{
    user: { session: { businessType } }
  },] = Object.values(useStateValue());

  const isWholesaler = businessType === 'WHOLESALER';

  const navigate = useNavigate();
  const isSelected = () => selected.indexOf(row.id) !== -1;
  const {
    dateCreated, brandName, quantityInStock, sellingPrice, status
  } = row;

  const price = currencyFormatter(sellingPrice);
  const [updateOrder] = useMutation(UPDATE_ORDER_MUTATION);
  const [updateProductStatus] = useMutation(UPDATE_PRODUCTS_STATUS_MUTATION);

  const [{
    user: { platform },
    cart: { isManualOrder }
  }, dispatch] = Object.values(useStateValue());
  const isErp = platform === 'erp';

  const getStatus = () => {
    if (status === 'ACTIVE') {
      return (
        <Box style={{ display: 'flex' }}>
          <StatusColor active />
          <StatusText>Enabled</StatusText>
        </Box>
      );
    }
    return (
      <Box style={{ display: 'flex' }}>
        <StatusColor />
        <StatusText>Disabled</StatusText>
      </Box>
    );
  };

  const renderCheckbox = () => (
    <CustomCheckbox
      size="small"
      checked={isSelected()}
      onChange={(e) => {
        e.stopPropagation();
        handleSelect(e, row.id);
      }}
      inputProps={{ 'aria-label': 'select product' }}
    />
  );

  const handleOptions = (event) => {
    setAction(action ? null : event.currentTarget);
  };

  const handleCloseModal = () => {
    setAction(false);
  };

  const handleAddToCart = (productId) => {
    updateOrder({
      variables: {
        productId,
        quantity: 1,
        isManualOrder: isErp || isManualOrder
      }
    })
      .then(({ data }) => {
        const { message, order } = data?.updateOrderProduct || {};
        dispatch({
          type: CartActionTypes.CART_COUNTER,
          payload: order?.supplierorderSet
        });
        toast.success(message);
      });
  };

  const updateProductStatusHandler = (type, productId) => {
    updateProductStatus({
      variables: {
        productIds: [productId],
        status: type ? 'IN_ACTIVE' : 'ACTIVE'
      }
    })
      .then(({ data }) => {
        const { message } = data?.updateProductStatus || {};
        toast.success(message);
        refetchData();
      })
      .catch((err) => {
        toast.error(err?.message);
      });
  };

  const handleAction = (event, type, productRow) => {
    if (type === 'add cart') {
      handleAddToCart(+productRow.id);
    } else if (type === 'add batch') {
      setOpenModal(true);
    } else {
      updateProductStatusHandler(type, productRow.id);
    }
    handleOptions(event);
  };

  const renderDate = moment(dateCreated).format('DD/MM/YY (h:mm)');

  return (
    <>
      <TableColumnRows data-testid="pure-mp-uat-product-tb-row" className="pure-mp-uat-product-tb-row">
        <TableColumnRowText data-testid="pure-mp-uat-product-tb-checkbox">{renderCheckbox()}</TableColumnRowText>
        <TableColumnRowText data-testid="pure-mp-uat-product-tb-name">{renderDate}</TableColumnRowText>
        <TableColumnRowText data-testid="pure-mp-uat-product-tb-sku" style={{ cursor: 'pointer' }} onClick={() => navigate(`/products/${row.id}/details`)}>
          {row?.sku}
        </TableColumnRowText>
        <TableColumnRowText data-testid="pure-mp-uat-product-tb-brandName" className="pure-mp-uat-product-tb-brandName" style={{ cursor: 'pointer' }} onClick={() => navigate(`/products/${row.id}/details`)}>
          {brandName}
        </TableColumnRowText>
        {isWholesaler ? (
          <TableColumnRowText data-testid="pure-mp-uat-product-tb-qty">
            <CustomInputNumberBase
              type="number"
              placeholder="0"
              min={1}
              size="medium"
              required
              value={quantityInStock}
              data-testid="input-field"
              onChange={(e) => counterHandler('change', row, e.target.value)}
              onWheel={(e) => e.target.blur()}
              disabled
              endAdornment={(
                <InputAdornment position="end">
                  <Grid item container direction="column">
                    <DisabledCaretUpIcon
                      style={{ width: '1rem', height: '1rem', pointerEvents: 'none' }}
                      onClick={() => counterHandler('increment', row, 0)}
                      data-testid="increment"
                    />
                    <DisabledCaretDownIcon
                      style={{ width: '1rem', height: '1rem', pointerEvents: 'none' }}
                      onClick={() => counterHandler('decrement', row, 0)}
                      data-testid="decrement"
                    />
                  </Grid>
                </InputAdornment>
              )}
              style={{ width: '100px', borderRadius: '5.6px', backgroundColor: '#FFFFFF' }}
            />
          </TableColumnRowText>
        ) : (
          <TableColumnRowText>{quantityInStock}</TableColumnRowText>
        )}

        <TableColumnRowText>{price}</TableColumnRowText>
        <TableColumnRowText>{getStatus()}</TableColumnRowText>
        <TableColumnRowText data-testid="pure-mp-uat-action-popper-td" className="pure-mp-uat-action-popper-td">
          <Dots fontSize="large" onClick={handleOptions} style={{ cursor: 'pointer' }} />
          <ActionPopper
            action={action}
            handleAction={handleAction}
            row={row}
            handleClose={handleCloseModal}
          />
        </TableColumnRowText>
      </TableColumnRows>

      <SingleBatchDialog
        open={openModal}
        onClose={() => setOpenModal(false)}
        brandName={brandName}
        productId={row?.id}
        refetch={refetchData}
      />
    </>

  );
};

ReturnRow.propTypes = {
  row: PropTypes.instanceOf(Product),
  selected: PropTypes.instanceOf(Array),
  handleSelect: PropTypes.func.isRequired,
  refetchData: PropTypes.func.isRequired,
  counterHandler: PropTypes.func.isRequired
};

ReturnRow.defaultProps = {
  row: {},
  selected: []
};

export default ReturnRow;
