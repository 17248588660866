import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Grid, useMediaQuery } from '@mui/material';
import CryptoJS from 'crypto-js';
import toast from 'react-hot-toast';
import { useMutation } from '@apollo/client';
import { LOGIN_MUTATION } from '../../mutations/auth';
import PasswordTextField from './passwordTextField';
import CustomButton from '../customComponents/customButton';
import {
  ForgotContainer, ForgotTypo, CircularProgressLoader, LinkStyled,
  HaveAccountTypo, ForgotButton, HaveAccount, InputWrapper, Label
} from './login.styles';
import { validateEmail } from './utils';
import { OgaToken } from '../../graphql/token';
import CustomInputBase from '../customComponents/customInputBase';
import { useStateValue } from '../../providers/stateProvider';
import UserActionTypes from '../../providers/reducers/user/userTypes';

const Login = () => {
  const [{ user: { userInfoRefetch } }, dispatch] = Object.values(useStateValue());
  const initialState = {
    email: '',
    password: '',
    firebaseToken: '',
    validated: false,
    selected: 'email',
  };
  const initialErrorState = {
    emailError: false,
    passwordError: false,
  };

  const [state, setState] = useState(initialState);
  const [errorState, setErrorState] = useState(initialErrorState);

  const {
    email, password
  } = state;
  const { emailError, passwordError } = errorState;
  const [loginUser, { loading }] = useMutation(LOGIN_MUTATION);

  const navigate = useNavigate();

  const validateState = (name, value) => {
    switch (name) {
      case 'email': return setErrorState({
        ...errorState,
        [`${name}Error`]: validateEmail(value) || !value.length
      });
      case 'password':
        return setErrorState({
          ...errorState,
          [`${name}Error`]: !value.length
        });
      default:
        return null;
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name === 'email' || name === 'password') {
      validateState(name, value);
      setState({ ...state, [name]: value });
    }
  };

  const validateSignInParams = () => {
    if (!email.length || validateEmail(email)) {
      setErrorState((_eState) => ({ ..._eState, emailError: true }));
      toast.error('Email is required');
      return false;
    }
    if (!password.length) {
      setErrorState((_eState) => ({ ..._eState, passwordError: true }));
      toast.error('Password is required');
      return false;
    }
    return true;
  };

  const routeUser = ({ businessType, user }) => {
    const { isBuyer, isSeller } = user;
    if (businessType === 'ADMIN') return navigate('/manage-accounts');
    if (isSeller) return navigate('/dashboard');
    if (isBuyer) return navigate('/orders');
  };

  const redirect = (response) => {
    const {
      token, restToken, businessType
    } = response;

    localStorage.setItem('oga_user_role', businessType);
    OgaToken.authToken = token;
    OgaToken.restToken = restToken;
    localStorage.setItem('oga_auth_token', token);
    localStorage.setItem('oga_rest_token', restToken);
    toast.success('Login successful');
    routeUser(response);
  };

  const handleEncrypt = (plainText, keyBase64, ivBase64) => {
    const key = CryptoJS.enc.Base64.parse(keyBase64);
    const iv = CryptoJS.enc.Base64.parse(ivBase64);
    const plainTextWordArray = CryptoJS.enc.Utf8.parse(plainText);

    const encrypted = CryptoJS.AES.encrypt(plainTextWordArray, key, {
      iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7
    });

    return encrypted.ciphertext.toString(CryptoJS.enc.Base64);
  };

  const handleSignIn = async () => {
    if (!validateSignInParams()) return false;

    const secretKey = `${process.env.SECRET_KEY}`;
    const iv = `${process.env.SECRET_IV}`;

    const encryptedEmail = handleEncrypt(email, secretKey, iv);
    const encryptedPassword = handleEncrypt(password, secretKey, iv);

    loginUser({
      variables: {
        email: encryptedEmail,
        password: encryptedPassword,
      }
    }).then(({ data }) => {
      dispatch({
        type: UserActionTypes.UPDATE_USER,
        payload: {
          businessType: data?.loginUser?.businessType,
          allPermissionsMap: [],
        }
      });
      userInfoRefetch();
      redirect(data?.loginUser);
    }).catch((err) => toast.error(err?.message));
  };

  const isLarge = useMediaQuery('(min-width: 992px)');

  return (
    <>
      <InputWrapper item container direction="column">
        <Grid item container justifyContent="space-between">
          <Label error={emailError || false}>
            Email Address
            {' '}
            <span style={{ color: 'red' }}>*</span>
          </Label>
        </Grid>
        <CustomInputBase
          variant="outlined"
          name="email"
          value={email}
          size={isLarge ? 'small' : 'medium'}
          type="text"
          required
          fullWidth
          error={emailError || false}
          helperText={emailError ? 'Invalid email' : ' '}
          onChange={handleChange}
          InputProps={{ disableUnderline: true }}
          className="uat-register-mobile"
          style={isLarge
            ? { height: '2.875rem', border: '.5px solid #c1c7c9' }
            : { height: '6rem', border: '2px solid #D0D5DD', borderRadius: '20px' }}
        />
      </InputWrapper>
      <PasswordTextField
        password={password}
        handleChange={handleChange}
        passwordError={passwordError}
        loading={loading}
        helperText="Password required"
      />
      <ForgotContainer item xs={12}>
        <ForgotButton>
          <ForgotTypo variant="caption">
            <LinkStyled to="/forgot-password">Forgot Password?</LinkStyled>
          </ForgotTypo>
        </ForgotButton>
      </ForgotContainer>
      <CustomButton
        onClick={handleSignIn}
        data-testid="auth-button"
        type="submit"
        className="signin-button"
        id="signin-button"
        disabled={loading}
        style={{ width: '100%', height: isLarge ? '3rem' : '5.7rem', fontSize: isLarge ? '' : '2rem' }}
      >
        {loading ? (
          <CircularProgressLoader
            disableShrink
            size={20}
            thickness={5}
          />
        ) : 'Log-in'}
      </CustomButton>
      <HaveAccount item xs={12}>
        <HaveAccountTypo variant="subtitle2" display="inline">
          Don&apos;t have an account?
          &nbsp;
          &nbsp;
          <Link to="/register" style={{ color: '#235A91', textDecoration: 'underline', fontWeight: '700' }}>Sign Up</Link>
        </HaveAccountTypo>
      </HaveAccount>
    </>
  );
};

Login.propTypes = {
};

Login.defaultProps = {
};

export default Login;
