import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import {
  BodyCell, MainTableRow,
} from './returnRow.styles';
import { Dots } from '../../../assets/svgs';
import ActionPopper from './actionPopper';

const ReturnRow = ({ row }) => {
  const [action, setAction] = useState(null);
  const navigate = useNavigate();
  const {
    id,
    totalBanner,
    page,
  } = row;

  const cells = [page, totalBanner];

  const returnCell = (value) => value || '-';

  const handleOptions = (event) => {
    setAction(action ? null : event.currentTarget);
  };

  const handleCloseModal = () => {
    setAction(false);
  };

  // eslint-disable-next-line no-unused-vars
  const handleAction = (event, type, _) => {
    if (type === 'view') {
      navigate('/banner-details', {
        state: { id, page }
      });
    } else if (type === 'upload') {
      navigate('/banner-details', {
        state: { id, page, actionType: 'upload' }
      });
    }
    handleOptions(event);
  };
  const renderBodyCells = () => cells.map((cell) => (
    <BodyCell key={cell}>{returnCell(cell)}</BodyCell>
  ));

  return (
    <MainTableRow item container key={id}>
      {renderBodyCells()}
      <BodyCell>
        <Dots fontSize="large" onClick={handleOptions} />
      </BodyCell>
      <ActionPopper
        action={action}
        handleAction={handleAction}
        row={row}
        handleClose={handleCloseModal}
      />
    </MainTableRow>
  );
};

ReturnRow.propTypes = {
  row: PropTypes.instanceOf(Object),
};

ReturnRow.defaultProps = {
  row: {},
};

export default ReturnRow;
