import { gql } from '@apollo/client';

export const PRODUCTS_TOTAL_QUERY = gql`
  query products($onlyBusinessProducts: Boolean, $search: String!, $suppliers: [String]!){
    products(search: $search suppliers: $suppliers, onlyBusinessProducts: $onlyBusinessProducts){
      id
    }
    productsTotalNumber
  }
`;
export const PRODUCT_VERSIONS = gql`
  fragment productVersions on ProductType {
    marketplaceVersion{
      outOfStock
      resolvedPriceInUseValue
      supplierCost
      priceInUse
      itemPrice
      fixedItemPrice
      firstOrderPromoPrice
      supplierMarkupPrice
      marketRrp
      productVariant
      minimumOrderQuantity
      status
      mktPrice
      promoValue
      savedPromoValue
      priceDifferenceValue
      priceDifferencePercentage
      category{
        categoryName
        id
        loyaltyWeight
        markup
        vatStatus
      }
      supplier{
        id
        name
      }
      brandName
      genericName
      packSize
      manufacturer
      image
      description
      therapeuticType{
        id
        therapeuticType
      }
      barcode
      unitOfMeasurement
      quantityInStock
    }
    erpVersion{
      dateCreated
      fixedSellingPrice
      resolvedPriceInUseValue
      priceInUse
      sellingPrice
      orderCost
      reorderPoint
      reorderUnit
      reorderMax
      isAVersion
      safetyStock
      maxLeadTime
      productVariant
      sku
      minimumOrderQuantity
      status
      category{
        categoryName
        id
        loyaltyWeight
        markup
        vatStatus
      }
      supplier{
        id
        name
      }
      brandName
      buffer
      reorderMin
      reorderQuantity
      genericName
      packSize
      manufacturer
      image
      description
      therapeuticType{
        id
        therapeuticType
      }
      barcode
      unitOfMeasurement
    }
    promoStatus
    promoEndDate
    promoCondition
  }
`;

export const TOP_SELLING_PRODUCT_VERSIONS = gql`
  fragment productVersions on ProductType {
    marketplaceVersion{
      resolvedPriceInUseValue
      mktPrice
      promoValue
      brandName
      packSize
      image
      quantityInStock
    }
    promoCondition
  }
`;

export const ERP_PRODUCT_QUERY = gql`
  query products(
    $search: String!, $status: String, $business: Int,
    $productType: String, $pageCount: Int, $pageNumber: Int, $productName: String, $productId: String, $dateFrom: String, $dateTo: String, $sku: String
  ){
    products(
      search: $search
      status: $status
      pageCount: $pageCount
      pageNumber: $pageNumber
      productType: $productType
      business: $business,
      productName: $productName
      productId: $productId
      dateFrom: $dateFrom
      dateTo: $dateTo
      sku: $sku
    ){
      id
      status
      business{
        id
      }
      id
      brandName
      molecule
      meta
      quantityInStock
      productClass
      sellingPrice
      sku
      dateCreated
      availableQuantity
    }
    productsTotalNumber
  }
`;

export const SEARCH_PO_QUERY = gql`
  query erpProducts(
    $search: String!, $status: String, $productName: String, $productId: String
  ){
    erpProducts(
      search: $search
      status: $status
      productName: $productName
      productId: $productId
    ){
      id
      brandName
      erpVersion{
        brandName
        orderCost
        resolvedPriceInUseValue
        supplier{
          name
        }
        packSize
      }
    }
  }
`;

export const GET_PO_ORDERS = gql`
query POOrderProducts($orderId:Int!, $pageCount:Int, $pageNumber:Int){
  poOrderProducts(orderId:$orderId, pageCount:$pageCount, pageNumber:$pageNumber){
    id
    unitCost
    price
    quantity
    product{
      id
      brandName
      meta
    }
    supplierOrder{
      id
      meta
      order{
        id
      }
      supplier {
        name
      }
    }
  }
  poOrderProductsCount
}`;

export const BATCH_PRODUCT_QUERY = gql`
  query erpProducts(
    $search: String!, $productId: String
  ){
    erpProducts(
      search: $search
      productId: $productId

    ){
      id
      erpVersion {
        brandName
        packSize
      }
      batches{
        id
        batchNo
        quantityRecieved
        orderCost
        dateRecieved
        expiryDate
        meta
        quantityInStock
      }
    }
  }
`;

export const SEARCH_PRODUCT_QUERY = gql`
  query erpProducts(
    $search: String!, $status: String, $productName: String, $productId: String, $pageCount: Int, $pageNumber: Int,
  ){
    erpProducts(
      search: $search
      status: $status
      productName: $productName
      productId: $productId
      pageCount: $pageCount
      pageNumber: $pageNumber
    ){
      id
      erpVersion{
        brandName
        resolvedPriceInUseValue
        packSize
      }
    }
  }
`;

export const PRODUCTS_QUERY = gql`
  query products(
    $search: String!, $suppliers: [String]!, $status: String, $business: Int, $priceMin: Float, $priceMax: Float,
    $productType: String, $therapeuticTypeIds: [String], $pageCount: Int, $pageNumber: Int, $onlyMarketProducts: Boolean,
    $onlyBusinessProducts: Boolean, $productName: String, $productId: String, $dateFrom: String, $dateTo: String, $category: Int, $productVariant: String
  ){
    products(
      search: $search
      suppliers: $suppliers
      pageCount: $pageCount
      pageNumber: $pageNumber
      productType: $productType
      therapeuticTypeIds: $therapeuticTypeIds
      onlyMarketProducts: $onlyMarketProducts
      onlyBusinessProducts: $onlyBusinessProducts
      status: $status
      business: $business,
      priceMin: $priceMin,
      priceMax: $priceMax,
      productName: $productName
      productId: $productId
      dateFrom: $dateFrom
      dateTo: $dateTo
      category: $category
      productVariant: $productVariant
    ){
      id
      status
      business{
        id
      }
      brandName
      productClass
      weeklyAverage
      quantityInStock
      genericName
      productRef
      supplier {
        id
        name
      }
      meta
      promo
      category {
        id
      }
      barcode
      batches{
        id
        batchNo
        quantityRecieved
        orderCost
        dateRecieved
        expiryDate
        meta
        quantityInStock
      }
      ...productVersions
    }
    productsTotalNumber
    queryTimestamp
  }
  ${PRODUCT_VERSIONS}
`;

export const PRODUCTS_SALE_QUERY = gql`
    ${PRODUCT_VERSIONS}
  query products(
    $search: String!, $suppliers: [String]!, $promos: [String], $pageCount: Int, $pageNumber: Int, $onlyMarketProducts: Boolean
  ){
    products(
      search: $search
      suppliers: $suppliers
      pageCount: $pageCount
      pageNumber: $pageNumber
      promos: $promos
      onlyMarketProducts: $onlyMarketProducts
    ){
      id
      batches{
        id
        meta
        quantityInStock
      }
      brandName
      genericName
      ...productVersions
      supplier {
        id
        name
      }
      meta
    }
    productsTotalNumber
  }
`;

export const PRODUCT_DETAILS_QUERY_MP = gql`
  query product($id: Int, $pageCount: Int, $pageNumber: Int, $affiliateId: Int) {
    product(id: $id, affiliateId: $affiliateId){
      id
      brandName
      genericName
      productClass
      molecule
      meta
      ...productVersions
      dateCreated
      supplier {
        id
        name
      }
      business {
        name,
        id
      }
      batches {
        id
        name
        meta
        dateRecieved
        batchNo
        quantityInStock
        quantityRecieved
        expiryDate
        orderPrice
        orderCost
        supplier {
          id
          name
        }
      }
      category {
        id
        categoryName
        markup
        vatStatus
        loyaltyWeight
      }
    }
    modelHistory(
      model: "Product", modelId: $id, pageCount: $pageCount, pageNumber: $pageNumber
    ) {
      message
      model
      modelGroup
      modelId
      business {
        name
      }
      businessUser{
        user {
          username
        }
      }
    }
    modelHistoryTotalNumber
  }
  ${PRODUCT_VERSIONS}
`;

export const PRODUCT_DETAILS_QUERY = gql`
  query product($id: Int) {
    product(id: $id){
      id
      status
      business{
        id
        name
      }
      id
      brandName
      molecule
      meta
      quantityInStock
      productClass
      sellingPrice
      sku
      dateCreated
      expiryDate
      marketSellingPrice
      manufacturer
      therapeuticType
      batches{
        id
        name
        expiryDate
        quantityInStock
        batchNo
      }
    }
  }
`;

export const ALL_PRODUCTS_QUERY = gql`
    ${PRODUCT_VERSIONS}
  query allProducts($onlyBusinessProducts: Boolean){
    allProducts(
      onlyBusinessProducts: $onlyBusinessProducts
    ){
      id
      business{
        id
      }
      brandName
      quantityInStock
      genericName
      productRef
      supplier {
        id
        name
      }
      meta
      ...productVersions
      barcode
      batches{
        id
        meta
        quantityInStock
      }
    }
  }
`;

export const PRODUCT_PERFORMANCE_QUERY = gql`
  query productsPerformance($pageCount: Int, $pageNumber: Int, $searchProductName: String,
   $businesses: [String], $sortBy: String, $export: Boolean, $affiliates: [Int],
    $dateFrom: DateTime, $dateTo: DateTime){
    productsPerformance(
      pageCount: $pageCount
      pageNumber: $pageNumber
      searchProductName: $searchProductName
      businesses: $businesses
      sortBy: $sortBy
      export: $export
      dateFrom: $dateFrom
      dateTo: $dateTo,
      affiliates: $affiliates
    ){
       name
       unitCost
       frequency
       packSize
       total
       quantity
       productId
       lastActivity
       grossMargin
    }
    productsTotalNumber
  }
`;

export const THERAPEUTIC_TYPE_REPORT = gql`
  query therapeuticTypePerformance(
    $pageCount: Int, $pageNumber: Int, $searchProductName: String,
    $businesses: [String], $sortBy: String, $export: Boolean,
    $affiliates: [Int], $dateFrom: DateTime, $dateTo: DateTime
  ){
    therapeuticTypePerformance(
      pageCount: $pageCount
      pageNumber: $pageNumber
      searchProductName: $searchProductName
      businesses: $businesses
      sortBy: $sortBy
      export: $export
      dateFrom: $dateFrom
      dateTo: $dateTo,
      affiliates: $affiliates
    ) {
      name
      unitCost
      frequency
      packSize
      total
      quantity
      productId
      lastActivity
      grossMargin
    }
    productsTotalNumber
  }
`;

export const GET_LATEST_PRODUCTS = gql`
  query lastestProducts(
    $lastQueryTimestamp: DateTime
  ){
    latestProducts(
      lastQueryTimestamp: $lastQueryTimestamp
    ){
      id
      business{
        id
      }
      brandName
      quantityInStock
      genericName
      productRef
      supplier {
        id
        name
      }
      meta
      promo
      category {
        id
      }
      barcode
      batches{
        id
        meta
        batchNo
        quantityRecieved
        orderCost
        dateRecieved
        expiryDate
        quantityInStock
      }
      ...productVersions
    }
    productsTotalNumber
    queryTimestamp
  }
  ${PRODUCT_VERSIONS}
`;

export const INVENTORY_REPORT_QUERY = gql`
  query inventoryReport(
    $searchProductName: String, $sortBy: String, $sortAsc: Boolean, $business: Int,
    $pageCount: Int, $pageNumber: Int, $dateFrom: DateTime, $dateTo: DateTime
  ){
    inventoryReport(
      searchProductName: $searchProductName
      sortBy: $sortBy
      sortAsc: $sortAsc
      pageCount: $pageCount
      business: $business
      pageNumber: $pageNumber
      dateFrom: $dateFrom
      dateTo: $dateTo
    ){
      id
      brandName
      genericName
      packSize
      therapeuticType
      supplierName
      manufacturer
      quantityInStock
      currentQuantitySaleAmount
      sellingPrice
      orderCost
      currentQuantityOrderCost
      quantityInStockPeriod
      dateCreated
      lastUpdated
      averageSalesPerDay
      totalUnitSold
      totalValueSold
      totalValueOrdered
    }
    productsTotalNumber
  }
`;

export const INVENTORY_ON_HAND_QUERY = gql`
  query inventoryProductInStock(
    $search: String, $businessId: Int, $pageCount: Int, $pageNumber: Int,
  ){
    inventoryProductInStock(
      search: $search
      businessId: $businessId
      pageCount: $pageCount
      pageNumber: $pageNumber
    ){
      summary{
        sellingPriceValuation
        orderCostValuation
        noOfProducts
      }
      products{
        id
        brandName
        genericName
        packSize
        quantityInStock
        orderCost
        orderCostValuation
        sellingPrice
        sellingPriceValuation,
      }
    }
    inventoryProductInStockTotalNumber
  }
`;

export const SIMILAR_PRODUCTS_QUERY = gql`
query similarProducts($searchProductIds: [Int]!, $therapeuticTypes: [String]!){
  similarProducts(
    therapeuticTypes: $therapeuticTypes
    searchProductIds: $searchProductIds
  ){
      id
      status
      business{
        id
      }
      brandName
      weeklyAverage
      genericName
      productRef
      userSubscriptionStatus
      expiryDate
      supplier {
        id
        name
      }
      meta
      promo
      category {
        id
      }
      barcode
      ...productVersions
    }
    similarProductsTotalNumber
    queryTimestamp
  }
  ${PRODUCT_VERSIONS}
`;

export const PRODUCTS_PAGE_QUERY = gql`
  query productPage(
    $search: String, $serviceProviders: [String], $priceMin: Float, $priceMax: Float,
    $productType: String, $therapeuticTypeIds: [String], $pageCount: Int, $pageNumber: Int
  ){
    productPage(
      search: $search
      pageCount: $pageCount
      pageNumber: $pageNumber
      productType: $productType
      therapeuticTypeIds: $therapeuticTypeIds
      serviceProviders: $serviceProviders
      priceMin: $priceMin,
      priceMax: $priceMax,
    ){
      id
      status
      business{
        id
      }
      brandName
      productClass
      weeklyAverage
      quantityInStock
      genericName
      productRef
      supplier {
        id
        name
      }
      meta
      promo
      category {
        id
      }
      barcode
      batches{
        id
        batchNo
        quantityRecieved
        orderCost
        dateRecieved
        expiryDate
        meta
        quantityInStock
      }
      ...productVersions
    }
    productsTotalNumber
    queryTimestamp
  }
  ${PRODUCT_VERSIONS}
`;

export const SALES_PRODUCTS_QUERY = gql`
  query salesPageProducts(
    $search: String!, $productType: String, $pageCount: Int, $pageNumber: Int, $status: String
  ){
    salesPageProducts(
      search: $search
      pageCount: $pageCount
      pageNumber: $pageNumber
      productType: $productType
      status: $status
    ){
      id
      status
      business{
        id
      }
      brandName
      productClass
      weeklyAverage
      quantityInStock
      genericName
      productRef
      supplier {
        id
        name
      }
      meta
      manufacturer
      promo
      category {
        id
      }
      barcode
      batches{
        id
        batchNo
        quantityRecieved
        orderCost
        dateRecieved
        expiryDate
        meta
        quantityInStock
      }
      ...productVersions
    }
    salesPageTotalNumber
    queryTimestamp
  }
  ${PRODUCT_VERSIONS}
`;

export const STOCK_TRANSFER_PRODUCT_QUERY = gql`
  query stockTransferProducts(
    $search: String!, $suppliers: [String], $business: Int, $pageCount: Int, $pageNumber: Int
  ){
    stockTransferProducts(
      search: $search
      pageCount: $pageCount
      pageNumber: $pageNumber
      suppliers: $suppliers
      business: $business
    ){
      id
      status
      business{
        id
      }
      brandName
      productClass
      weeklyAverage
      quantityInStock
      genericName
      productRef
      supplier {
        id
        name
      }
      meta
      promo
      category {
        id
      }
      barcode
      batches{
        id
        batchNo
        quantityRecieved
        orderCost
        dateRecieved
        expiryDate
        meta
        quantityInStock
      }
      ...productVersions
    }
    stockTransferProductsTotalNumber
    queryTimestamp
  }
  ${PRODUCT_VERSIONS}
`;

export const CLEARANCE_ORDER_PRODUCT = gql`
query clearanceOrderPageProducts($search: String, $priceMin: Float, $priceMax: Float, $therapeuticTypeIds: [String], $pageCount: Int, $pageNumber: Int, $batchId: Int) {
  clearanceOrderPageProducts(
    search: $search
    pageCount: $pageCount
    pageNumber: $pageNumber
    therapeuticTypeIds: $therapeuticTypeIds
    priceMin: $priceMin
    priceMax: $priceMax
    batchId: $batchId
  ) {
    id
    name
    batchNo
    discount
    discountedPrice
    quantityInStock
    expiryStatus
    expiryDate
    price
    product {
      id
      brandName
      genericName
      marketplaceVersion{
        outOfStock
        resolvedPriceInUseValue
        supplierCost
        marketRrp
        productVariant
        status
        brandName
        genericName
        packSize
        image
      }
      supplier {
      id
      name
      __typename
    }
    }
    meta
    __typename
  }
  clearanceOrderPageProductsTotalNumber
  queryTimestamp
}
`;

export const CLEARANCE_SALES_DETAILS = gql`
query clearanceOrderPageProducts($search: String, $priceMin: Float, $priceMax: Float, $therapeuticTypeIds: [String], $pageCount: Int, $pageNumber: Int, $batchId: Int) {
  clearanceOrderPageProducts(
    search: $search
    pageCount: $pageCount
    pageNumber: $pageNumber
    therapeuticTypeIds: $therapeuticTypeIds
    priceMin: $priceMin
    priceMax: $priceMax
    batchId: $batchId
  ) {
    id
    name
    batchNo
    discount
    discountedPrice
    quantityInStock
    expiryStatus
    expiryDate
    price
    product {
      id
      brandName
      genericName
      meta
      molecule
      batches{
        id
        expiryDate
      }
      marketplaceVersion{
      outOfStock
      resolvedPriceInUseValue
      supplierCost
      priceInUse
      itemPrice
      fixedItemPrice
      firstOrderPromoPrice
      supplierMarkupPrice
      marketRrp
      productVariant
      minimumOrderQuantity
      status
      category {
        categoryName
        id
        loyaltyWeight
        markup
        vatStatus
        __typename
      }
      supplier {
        id
        name
        __typename
      }
      brandName
      genericName
      packSize
      manufacturer
      image
      description
      therapeuticType {
        id
        therapeuticType
        __typename
      }
      barcode
      unitOfMeasurement
      quantityInStock
    }
  }
    meta
    __typename
  }
  clearanceOrderPageProductsTotalNumber
  queryTimestamp
}
`;

export const CLEARANCE_SEARCH_ORDER_PRODUCT = gql`
query clearanceOrderPageProducts($search: String, $suggestive: Boolean) {
  clearanceOrderPageProducts(
    search: $search, suggestive: $suggestive
  ) {
    name
  }
}
`;

export const NEW_ORDER_PAGE_PRODUCT_VERSIONS = gql`
  fragment productVersions on ProductType {
    promoStatus
    promoEndDate
    promoCondition
  }
`;

export const NEW_ORDER_PRODUCTS_PAGE_QUERY = gql`
  query products(
    $search: String!, $pageCount: Int, $pageNumber: Int, $marketProducts: Boolean,
    $productName: String, $priceRange: PriceEnum, $productVariant: ProductVariantEnum
  ){
    products(
      search: $search
      pageCount: $pageCount
      pageNumber: $pageNumber
      marketProducts: $marketProducts
      productName: $productName
      priceRange: $priceRange
      productVariant: $productVariant
    ){
      id
      brandName
      business{
        id
        name
      }
      id
      brandName
      meta
      sellingPrice
      marketSellingPrice
      expiryDate
      quantityInStock
      availableQuantity
      manufacturer
    }
    productsTotalNumber
  }
`;

export const NEW_ORDER_PRODUCTS_SEARCH_QUERY = gql`
  query products(
    $search: String!, $marketProducts: Boolean, $productName: String, $pageCount: Int, $pageNumber: Int,
    $productVariant: ProductVariantEnum
  ){
    products(
      search: $search,
      marketProducts: $marketProducts
      productName: $productName,
      pageCount: $pageCount,
      pageNumber: $pageNumber
      productVariant: $productVariant
    ){
      brandName
    }
  }
`;

export const RECOMMENDED_PRODUCTS = gql`
  query recommendedProducts(
    $productId: Int!, $pageCount: Int, $pageNumber: Int
  ){
    recommendedProducts(
      productId: $productId
      pageCount: $pageCount
      pageNumber: $pageNumber
    ){
      id
      brandName
      business{
        id
        name
      }
      id
      brandName
      meta
      sellingPrice
    }
    productsTotalNumber
  }
`;

export const CART_RECOMMENDED_PRODUCTS = gql`
  query recommendedProducts{
    cartRecommendedProducts{
      id
      brandName
      business{
        id
        name
      }
      id
      brandName
      meta
      sellingPrice
    }
    productsTotalNumber
  }
`;

export const TOP_SELLING_PRODUCTS = gql`
  query topSellingProducts($pageCount: Int, $pageNumber: Int) {
    topSellingProducts(
      pageCount: $pageCount,
      pageNumber: $pageNumber
    ) {
      id
      brandName
      promoCondition
      __typename
      ...productVersions
  }
  topSellingProductsTotalNumber
  }
  ${TOP_SELLING_PRODUCT_VERSIONS}
`;

export const STOCK_COUNT_PRODUCTS_QUERY = gql`
  query stockCount(
    $search: String!, $suppliers: [String]!, $onlyMarketProducts: Boolean,
    $onlyBusinessProducts: Boolean, $business: Int, $pageCount: Int,
    $pageNumber: Int
  ){
    stockCount(
      search: $search
      business: $business
      suppliers: $suppliers
      pageCount: $pageCount
      pageNumber: $pageNumber
      onlyMarketProducts: $onlyMarketProducts
      onlyBusinessProducts: $onlyBusinessProducts,
    ){
      id
      business{
        id
      }
      brandName
      productClass
      weeklyAverage
      quantityInStock
      genericName
      productRef
      supplier {
        id
        name
      }
      meta
      promo
      category {
        id
      }
      barcode
      batches {
        id
        batchNo
        expiryDate
        quantityInStock
        orderCost
      }
      ...productVersions
    }
    productsTotalNumber
    queryTimestamp
  }
  ${PRODUCT_VERSIONS}
`;

export const PRODUCT_ACTION_TIMELINE = gql`
  query productActionTimelines($productId: Int!, $pageCount: Int, $pageNumber: Int, $dateFrom: DateTime, $dateTo: DateTime){
      productActionTimelines(
      productId: $productId,
      dateFrom: $dateFrom,
      dateTo: $dateTo,
      pageCount: $pageCount,
      pageNumber: $pageNumber,
    ){
      lastUpdatedBy
      lastUpdatedDate
      lastUpdatedField
      previousValue
      updatedValue
    }
  productActionTimelinesTotalNumber
}
`;

export const ALL_PRODUCT_BATCHES_QUERY = gql`
  query allProductBatches(
    $searchProductName: String, $searchBatchNumber: String, $pageCount: Int,
    $pageNumber: Int, $dateFrom: DateTime, $dateTo: DateTime, $status: String, $dateFilterBy: String, $sku: String
  ){
    allProductBatches(
      searchProductName: $searchProductName
      searchBatchNumber: $searchBatchNumber
      pageCount: $pageCount
      pageNumber: $pageNumber
      dateFrom: $dateFrom
      dateTo: $dateTo
      status: $status
      dateFilterBy: $dateFilterBy
      sku: $sku
    ){
      id
      dateCreated
      dateReceived
      expiryDate
      batchNo
      product {
        id
        brandName
      }
      quantityInStock
      lastUpdatedField
      lastUpdatedBy
      lastUpdatedDate
      previousInfo
      currentInfo
      meta
      status
    }
    allProductBatchesTotalNumber
  }
`;

export const PRODUCT_BATCH_LOGS_QUERY = gql`
  query allProductBatchLogs(
    $batchId: String, $pageCount: Int, $pageNumber: Int
  ){
    allProductBatchLogs(
      batchId: $batchId
      pageCount: $pageCount
      pageNumber: $pageNumber
    ){
      date
      orderCost
      expiryDate
      quantity
      updatedField
      updatedBy
      updateDate
      previousValue
      currentValue
      productName
      batchNo
    }
    allProductBatchLogsTotalNumber
  }
`;

export const PRODUCT_ORDER_LOGS_QUERY = gql`
  query productOrderLogs(
    $productId: String!, $pageCount: Int, $pageNumber: Int, $dateFrom: String, $dateTo: String, $orderId: String, $returned: Boolean
  ){
    productOrderLogs(
      productId: $productId
      pageCount: $pageCount
      pageNumber: $pageNumber
      dateFrom: $dateFrom
      dateTo: $dateTo
      orderId: $orderId
      returned: $returned
    ){
      id
      batchNo
      batch
      meta
      dateCreated
      product {
          brandName
      }
      order {
          id
      }
    }
    productOrderLogsTotalNumber
  }
`;

export const PRODUCT_MERCHANDISE_QUERY = gql`
  query erpProducts(
    $search: String!, $status: String, $productName: String, $productId: String
  ){
    erpProducts(
      search: $search
      status: $status
      productName: $productName
      productId: $productId
    ){
      id
      brandName
      erpVersion{
        brandName
      }
    }
  }
`;

export const PRODUCT_SETTING_DISPLAY_QUERY = gql`
  query productDisplaySettings{
    productDisplaySettings{
      enabled
      displayOption
      products {
          productId
          productName
          productPosition
      }
    }
  }
`;
